import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import '../styles/colors.scss'
import SEO from "../components/seo"
export default class BlogList extends React.Component {
  
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteSlogan = data.site.siteMetadata.slogan
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    const frontmatter = {
      slug: this.props.location.pathname.replace(/[^\w\d-]/g, '')
    };

    return (
      <Layout title={siteTitle} slogan={siteSlogan}>
        <SEO 
          title={ currentPage == 1 ? "Blog Articles About Electric Vehicles" : `All Posts (page ${currentPage} of ${numPages})`}
          description="Read useful articles on the topic of electric vehicles from EV enthusiasts. Here you'll find tips and tricks and helpful practical advice on different aspects of your e-vehicle."
          frontmatter={frontmatter}
        />
        <main>
          <h1 className="blog-heading">Latest Blog Posts</h1>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div key={node.fields.slug} className="blog-post">
                <h2
                >
                  <Link className="heading-link" style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h2>
                <small>{node.frontmatter.date}</small>
                <Img
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
               
                <Link className="blog-post-continue-btn" style={{ boxShadow: `none` }} to={node.fields.slug}>
                  <span className="text1">Continue Reading</span>
                </Link>
  
              </div>
            )
          })}
            <ul className='pagination'>
              <li>
            {!isFirst && (
              <Link className="prev" to={`/blog/${prevPage}`} rel="prev">
                ← Prev
              </Link>
            )}
            </li>
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
              >
                <Link
                  to={`/blog/${i === 0 ? '' : i + 1}`}
                  style={{
                    color: i + 1 === currentPage ? '#ffffff' : '',
                    background: i + 1 === currentPage ? '#007acc' : '',
                  }}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
            <li>
            {!isLast && (
              <Link className="next" to={`/blog/${nextPage}`} rel="next">
                Next →
              </Link>
            )}
            </li>
          </ul>
        </main>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp{
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`